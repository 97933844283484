<script setup>
const app = useNuxtApp()
const themeStore = useThemeStore()

const IS_SSR = app.ssrContext
const domain = IS_SSR ? app?.ssrContext?.event?.node?.req?.headers?.host : window.location.host
const {data} = await useAsyncData('theme', () => themeStore.getThemeData(domain))

const themeData = data.value
const themeJson = themeData?.theme_json
const baseUrl = themeData?.base_url
const paymentIconMap = themeJson?.assets?.payment_icons
const themePaymentIcons = themeJson?.payment_icons

const themePaymentIconsList = computed(() => {
  const paymentIcons = themePaymentIcons?.map((payment) => {
    const paymentIconPath = paymentIconMap ? paymentIconMap[`${payment.name}`] : ''
    return {
      custom_css_class: payment.custom_css_class ? payment.custom_css_class : null,
      payment_icon_url: paymentIconPath ? `${baseUrl}${themeJson.asset_base_path}${paymentIconPath}` : '',
      payment_icon_name: payment.name,
    }
  })
  return paymentIcons || []
})
</script>

<style lang="scss" scoped>
@import 'assets/css/components/global/nu-payment-icons.css';
/* image path require nuxt compile */
.payment-icon {
  &.visa {
    background-image: url(assets/images/visa.svg);
  }
  &.americanexpress {
    background-image: url(assets/images/americanexpress.svg);
  }
  &.dinersclub {
    background-image: url(assets/images/dinersclub.svg);
  }
  &.master-card {
    background-image: url(assets/images/master-card.svg);
  }
  &.cirrus {
    background-image: url(assets/images/cirrus.svg);
  }
  &.jcb {
    background-image: url(assets/images/jcb.svg);
  }
  &.union-pay {
    background-image: url(assets/images/union-pay.svg);
  }
  &.paypal {
    background-image: url(assets/images/paypal.svg);
  }
  &.maestro {
    background-image: url(assets/images/maestro.svg);
  }
}
</style>

<template>
  <ul class="nu-payment-icons-wrapper">
    <li
      data-testid="payment-method"
      :key="index"
      v-for="(payments, index) in themePaymentIconsList"
    >
      <img
        :alt="payments.payment_icon_name"
        :class="`${payments.custom_css_class} ${payments.payment_icon_name}`"
        :data-testid="`${payments.payment_icon_name}-custom-icon`"
        :src="payments.payment_icon_url"
        class="payment-icon"
        v-if="payments.payment_icon_url"
      />
      <span
        :class="'payment-icon ' + payments.payment_icon_name"
        :data-testid="`${payments.payment_icon_name}-default-icon`"
        v-else
      ></span>
    </li>
  </ul>
</template>
